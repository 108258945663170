import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import HeaderImageSrc from "../media/images/contact_background.jpg"

// import components
import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"
import HeaderImage from "../components/headerImage"
import GoogleReviews from "../components/google_reviews/google_reviews"

// import templates
import BasicLayout from "../templates/basic-layout"

// styled components
import styled from "styled-components"
import { fontSize } from "../css/mixins/mixins"
const MapContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;
`

const GoogleMap = styled.iframe`
  margin: 0;
  padding: 0;
  height: 400px;

  @media only screen and (min-width: 930px) {
    height: 600px;
  }
`

const PDFTitle = styled.p`
  ${fontSize(22)}
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;
  position: relative;
  color: #000;
  flex-basis: 100%;

  @media only screen and (min-width: 930px) {
    ${fontSize(30)}
  }

  &:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const PDFList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 80px;
`

const PDFItem = styled.li`
  background-color: #eeeeee;
  border: 2px #e9e9e9 solid;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: 0.5s background-color, 0.5s border;
  overflow: hidden;
  flex-basis: calc(50% - 10px);
  margin: 5px;
  text-align: center;

  &:hover {
    background-color: #3a3a3a;
    border: 2px #917147 solid;
    span {
      text-decoration: underline;
    }
  }

  a {
    padding: 30px 10px;
    display: block;
    text-decoration: none;
    font-weight: 700;
  }
`

const seo_description = `To find out about Hove & Brighton personal training, contact Ty Paul with over 16 years experience offering 5* rated premium Personal Training service.`
const seo_keywords = `Contact Ty Paul, Britain's Best Coach, Men's Health Magazine, 5* rating, personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const Contact = () => {
  const data = useStaticQuery(query)
  const { strapiContact } = data
  const { title, description, SEO } = strapiContact

  const {
    page_description = seo_description,
    page_keywords = seo_keywords,
    page_title = title,
  } = SEO

  return (
    <Layout>
      <Seo
        title={page_title}
        description={page_description}
        keywords={page_keywords}
      />
      <HeaderImage src={HeaderImageSrc} title={title} />
      <BasicLayout pageName={title} description={description} />
      <div className="container">
        <PDFTitle>Online Consent Form</PDFTitle>
        <PDFList>
          <PDFItem>
            <a
              href={"https://form.jotform.com/221294986913063"}
              target="_blank"
              rel="noreferrer"
            >
              Informed Consent Form
            </a>
          </PDFItem>
          <PDFItem>
            <a
              href={"https://form.jotform.com/221291419928057"}
              target="_blank"
              rel="noreferrer"
            >
              Health Screening Form
            </a>
          </PDFItem>
        </PDFList>
      </div>
      <MapContainer>
        <div className="container">
          <GoogleMap
            src={
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.8062307108717!2d-0.20364698372648746!3d50.85327237953302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875900b7e6d2447%3A0xd0dfed8101248e30!2sTy%20Paul%20Personal%20Trainer%20Hove%20%26%20Steyning!5e0!3m2!1sen!2suk!4v1649860011144!5m2!1sen!2suk"
            }
            width={"100%"}
            height={"100%"}
            style={{ border: 0 }}
            allowFullScreen={""}
            loading={"lazy"}
            referrerpolicy={"no-referrer-when-downgrade"}
          ></GoogleMap>
        </div>
      </MapContainer>
      <GoogleReviews />
    </Layout>
  )
}

const query = graphql`
  {
    strapiContact {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default Contact
